/* eslint-disable no-unused-vars */
const yellow1 = "#fef9d1"
const yellow2 = "#fbf3a2"
const yellow3 = "#fdef74"
const yellow4 = "#faeb49"
const yellow = "#fcea10"

const blue1 = "#d2e2ec"
const blue2 = "#a7c7db"
const blue3 = "#7aaac9"
const blue4 = "#4191b9"
const blue = "#0077a8"

const black1 = "#d0d0d0"
const black2 = "#a1a1a1"
const black3 = "#757474"
const black4 = "#4a4a49"
const black = "#1d1d1d"

const white = "#ffffff"
/* eslint-enable no-unused-vars */

const defaultColors = {
  color: black,
  backgroundColor: undefined,

  shadowColor: black3,

  linkUnderlineColor: yellow,
  linkUnderlineHoverColor: blue4,

  navLinkVisitedColor: undefined,
  navLinkHoverColor: blue,
  navLinkActiveColor: blue,

  listItemColor: blue,

  callToActionVisitedColor: black3,
  callToActionHoverColor: blue,

  callToActionVariantColor: black,
  callToActionVariantBackgroundColor: yellow,
  callToActionVariantHoverColor: white,
  callToActionVariantHoverBackgroundColor: blue,

  publishDateColor: white,
  publishDateBackgroundColor: blue,

  readingTimeColor: blue3,
  readingTimeBackgroundColor: blue1,

  imageSliderProgressColor: blue4,

  mapMarkerColor: blue,
  mapSelectedMarkerColor: yellow,
  mapOverlayBackgroundColor: black,

  partnersToggleActiveColor: white,
  partnersToggleActiveBackgroundColor: blue,
  partnersToggleHoverBackgroundColor: blue1,

  teamVacancyPlaceholderColor: yellow,

  trainingBackgroundColor: blue2,
  prospectiveTrainingColor: black3,
  prospectiveTrainingBackgroundColor: blue1,
}

const headerColors = {
  backgroundColor: white,
}

const menuColors = {
  color: white,
  backgroundColor: blue,

  linkUnderlineHoverColor: blue2,
  navLinkVariantVisitedColor: black1,
  navLinkVisitedColor: black2,
  navLinkHoverColor: blue1,
  navLinkActiveColor: blue1,
}

const contactColors = {
  color: black,
  backgroundColor: blue2,

  linkUnderlineHoverColor: blue4,

  inputColor: black,
  inputBackgroundColor: white,
  inputFocusOutlineColor: blue3,
  inputPlaceholderColor: black3,
}

const footerColors = {
  color: white,
  backgroundColor: black,

  navLinkHoverColor: blue3,
  navLinkActiveColor: blue3,

  inputColor: black,
  inputBackgroundColor: white,
  inputFocusOutlineColor: blue3,
  inputPlaceholderColor: black3,

  buttonHoverColor: blue3,
}

// section Leistungen
const contrastSectionColors = {
  color: white,
  backgroundColor: blue,

  callToActionVisitedColor: black1,
  callToActionHoverColor: blue3,
}

const layout = {
  maxLayoutWidth: `1100px`,
  headerHeightNarrow: `4em`,
  shrunkHeaderHeightNarrow: `4rem`,
  headerHeight: `80px`,
  shrunkHeaderHeight: `calc(max(4rem, 80px))`,
  headerHeightWide: `6em`,
}

const readingTimeGrowDelaySeconds = 0.5
const readingTimeGrowDurationSeconds = 1
const readingTimeFadeInDelaySeconds =
  readingTimeGrowDelaySeconds + readingTimeGrowDurationSeconds
const readingTimeFadeInDurationSeconds = 0.5

const transitions = {
  readingTimeGrowDelay: `${readingTimeGrowDelaySeconds}s`,
  readingTimeGrowDuration: `${readingTimeGrowDurationSeconds}s`,
  readingTimeFadeInDelay: `${readingTimeFadeInDelaySeconds}s`,
  readingTimeFadeInDuration: `${readingTimeFadeInDurationSeconds}s`,
}

export const defaultTheme = {
  ...defaultColors,
  ...layout,
  ...transitions,
}

export const headerTheme = headerColors

export const menuTheme = menuColors

export const contactTheme = contactColors

export const footerTheme = footerColors

export const contrastSectionTheme = contrastSectionColors
