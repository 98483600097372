import React from "react"
import { ThemeProvider } from "styled-components"

import { defaultTheme } from "./theme"
import { ModalContextProvider } from "./modal-context"

const RootLayout = ({ children }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <ModalContextProvider>{children}</ModalContextProvider>
    </ThemeProvider>
  )
}

export default RootLayout
