import React from "react"

export const ModalContext = React.createContext(null)

export const ModalContextProvider = ({ children }) => {
  const [currentModal, setCurrentModal] = React.useState(null)

  const showMenu = React.useCallback(() => {
    setCurrentModal({ type: "menu" })
  }, [])
  const showInquiryForm = React.useCallback(initialSubject => {
    setCurrentModal({ type: "contact", intent: "inquiry", initialSubject })
  }, [])
  const showApplicationForm = React.useCallback(initialSubject => {
    setCurrentModal({ type: "contact", intent: "application", initialSubject })
  }, [])
  const hideModal = React.useCallback(() => {
    setCurrentModal(null)
  }, [])

  const modalContextValue = React.useMemo(
    () => ({
      currentModal,
      showMenu,
      showInquiryForm,
      showApplicationForm,
      hideModal,
    }),
    [currentModal, showMenu, showInquiryForm, showApplicationForm, hideModal]
  )

  return (
    <ModalContext.Provider value={modalContextValue}>
      {children}
    </ModalContext.Provider>
  )
}
