// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-akademie-js": () => import("./../src/pages/akademie.js" /* webpackChunkName: "component---src-pages-akademie-js" */),
  "component---src-pages-impressum-datenschutz-js": () => import("./../src/pages/impressum-datenschutz.js" /* webpackChunkName: "component---src-pages-impressum-datenschutz-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leistungen-js": () => import("./../src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-leitbild-js": () => import("./../src/pages/leitbild.js" /* webpackChunkName: "component---src-pages-leitbild-js" */),
  "component---src-pages-magazin-js": () => import("./../src/pages/magazin.js" /* webpackChunkName: "component---src-pages-magazin-js" */),
  "component---src-pages-partner-js": () => import("./../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-author-articles-js": () => import("./../src/templates/author-articles.js" /* webpackChunkName: "component---src-templates-author-articles-js" */)
}

